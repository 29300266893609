export default {
  computed: {
  },
  methods: {

    allowAddItem() {
      if (
        !this.form ||
        !this.form.expense_date
      ) {
        return false;
      }
      if (
        !this.form ||
        !this.form.person ||
        !this.form.person.id
      ) {
        return false;
      }
      return true;
    },
    isValid(item) {

      if (!item) {
        return false;
      }
      if (!item.rule || !item.rule.id) {
        return false;
      }
      if (!item.payment_method || !item.payment_method.id) {
        return false;
      }      
      if (item.reference_number == "" || item.reference_number == null) {
        return false;
      }
      if (item.received_value == "" || item.received_value == null || item.received_value == 0) {
        return false;
      }
      return true;
    },
    getBaseItem(product, variant) {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          this.index = "_" + new Date().getTime();
          // this.index = "";
          const item = {
            id: this.index,
            rule: {},
            payment_method: {},
            reference_number: "",
            received_value: 0,
            is_new: true,
            _editing: false,
            _deleted: false,
          };
          resolve(item);
        }, 10);
      });
    }    
  }
}