<template>
  <div>
    <div v-for="(item, index) in items" :key="index">
      <v-row no-gutters class="mt-4">
        <v-col cols="5" class="font-weight-regular"
          >{{ $t("Concepto") }}:</v-col
        >
        <v-col cols="7" class="font-weight-regular text-right">
          <div v-if="item.rule">{{ item.rule.name }}</div>
        </v-col>

        <v-col cols="5" class="font-weight-regular"
          >{{ $t("Método de Pago") }}:</v-col
        >
        <v-col cols="7" class="font-weight-regular text-right">
          <div v-if="item.payment_method">{{ item.payment_method.name }}</div>
        </v-col>

        <v-col cols="5" class="font-weight-regular"
          >{{ $t("No. Referencia") }}:</v-col
        >
        <v-col cols="7" class="font-weight-regular text-right">
          <div v-if="item.reference_number">{{ item.reference_number }}</div>
        </v-col>

        <v-col cols="5" class="font-weight-regular"
          >{{ $t("Valor Recibido") }}:</v-col
        >
        <v-col cols="7" class="font-weight-regular text-right">
          <div v-if="item.received_value">
            {{ $filters.currency(item.received_value, 0) }}
          </div>
        </v-col>

        <v-col cols="12">
          <div
            class="d-flex justify-space-around my-3"
            v-if="form && !disabled"
          >
            <v-btn small @click="editModal(item)" icon color="primary"
              ><v-icon>mdi-pencil</v-icon> {{ $t("Editar") }}
            </v-btn>
            <v-btn small @click="deleteItem(item)" icon color="error"
              ><v-icon>mdi-delete</v-icon> {{ $t("Eliminar") }}
            </v-btn>
          </div>
        </v-col>
      </v-row>

      <v-divider></v-divider>
    </div>
    <div class="d-flex justify-end mt-4 mb-4" v-if="!disabled">
      <v-btn
        block
        small
        color="primary"
        :title="$t('Agregar Detalle')"
        @click="addItem"
        :disabled="!allowAddItem()"
        ><v-icon>mdi-plus</v-icon> {{ $t("Agregar Detalle") }}</v-btn
      >
    </div>

    <v-dialog v-model="dialogEdit" persistent max-width="680">
      <v-card>
        <DetailModalEdit
          @closeEditModal="closeEditModal"
          @saveModalItem="saveModalItem"
          @saveModalItemAndAdd="saveModalItemAndAdd"
          :item="currentItem"
        ></DetailModalEdit>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import NextMixinDetails from "@/components/mixins/NextMixinDetails";
import NextMixinDetailsMobil from "@/components/mixins/NextMixinDetailsMobil";
import MixinDetailsExpense from "./MixinDetailsExpense";
import DetailModalEdit from "./DetailModalEdit.vue";

export default {
  name: "NgDetailsMobileView",
  components: { DetailModalEdit },
  mixins: [NextMixinDetails, NextMixinDetailsMobil, MixinDetailsExpense],
  data() {
    return {};
  },
  props: ["items", "form", "disabled"],
  methods: {},
};
</script>
