<template>
  <v-card :key="key">

    <v-app-bar flat color="primary">
      <v-toolbar-title class="text-h6 white--text pl-0">
        {{ $t("detail") }}
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn color="white" icon @click="$emit('closeEditModal')" :title="$t('Cancel')">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-app-bar>
    <v-card-text class="pb-0" style="max-height: 60vh; overflow-y: auto;">
      <v-list class="py-3 pb-0" v-if="item">
        <v-list-item>
          <v-list-item-content class="py-2">
            <rule-selector v-model="item.rule" label="rule" rules="required" url="api/v1/list/rules"></rule-selector>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content class="py-2">
            <payment-method-selector v-model="item.payment_method" label="payment_method" rules="required"
              url="api/v1/list/payment-method"></payment-method-selector>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content class="py-2">
            <ng-text dense hide-details v-model="item.reference_number" label="No. Referencia"> </ng-text>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content class="py-2">
            <ng-money dense hide-details v-model="item.received_value" label="Valor Recibido" rules="required">
            </ng-money>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn small :disabled="!isValid(item)" color="primary" @click="$emit('saveModalItem')" :title="$t('Guardar')">
        <v-icon>mdi-content-save</v-icon>{{ $t("apply") }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>

import DetailRowEdit from "./DetailRowEdit.vue";

export default {
  name: "DetailModalEdit",
  mixins: [DetailRowEdit],
  data() {
    return {
      key: 0,      
      config: {
        reference_number: {
          label: "",
          "hide-details": true,
        },
        cost: {
          label: "",
          "hide-details": true,
        },
        rule: {
          label: "",
          url: "api/v1/list/rules",
          "hide-details": true,
        },
        payment_method: {
          label: "",
          url: "api/v1/list/payment-method",
          "hide-details": true,
        },
      },
    };
  },
};
</script>