<template>
	<table style="width: 100%" class="my-4">
		<tr>
			<td class="py-2" colspan="5">
				<v-row>
					<v-col cols="12" md="3">
						<rule-selector v-model="form.rule" label="rule" rules="required" hide-details dense
							url="api/v1/list/rules"></rule-selector>
					</v-col>
					<v-col cols="12" md="3">
						<payment-method-selector v-model="form.payment_method" label="payment_method" rules="required"
							hide-details dense url="api/v1/list/payment-method"></payment-method-selector>
					</v-col>
					<v-col cols="12" md="3">
						<ng-text hide-details dense v-model="form.reference_number" label="No. Referencia"> </ng-text>
					</v-col>
					<v-col cols="12" md="3">
						<ng-money hide-details dense v-model="form.received_value" label="Valor Recibido" rules="required|min:1">
						</ng-money>
					</v-col>
				</v-row>
				<v-row>
					<v-col cols="12" class="text-right container-detail-buttons mt-0 pt-0">
						<v-btn @click="saveItemChanges(form)" outlined :disabled="!isValid(form)" small color="primary"
							id="btn-detail-save-content" :title="$t('apply')">
							<v-icon>mdi-check</v-icon> {{ $t("apply") }}
						</v-btn>

						<v-btn @click="$emit('cancel', form)" outlined small color="grey darken-3" v-if="!form.is_new"
							id="btn-detail-cancel" class="ml-1" :title="$t('cancel')">
							<v-icon>mdi-cancel</v-icon>{{ $t("cancel") }}
						</v-btn>

						<v-btn @click="$emit('delete', form)" small outlined color="error" id="btn-detail-delete"
							class="ml-1 white--text" :title="$t('delete')">
							<v-icon>mdi-delete-outline</v-icon>{{ $t("delete") }}
						</v-btn>
					</v-col>
				</v-row>
			</td>
		</tr>
	</table>
</template>
<script>

export default {
	name: "DetailRowEdit",
	data() {
		return {
			form: { rule: null, payment_method: null }
		};
	},
	props: ["item"],
	mounted() {
		this.form = this.item;
	},
	computed: {
	},
	methods: {
		isValid(item) {
			if (!item) {
				return false;
			}
			if (!item.rule || !item.rule.id) {
				return false;
			}
			if (!item.payment_method || !item.payment_method.id) {
				return false;
			}
			if (item.received_value == "" || item.received_value == null || item.received_value == 0) {
				return false;
			}

			return true;
		},
		saveItemChanges(item) {
			if (this.isValid(item)) {
				item._editing = false;
				this.$emit('save:item', item)
			}
		},
		compare(a, b) {
			if (a && b) {
				if (a.id && b.id && a.id === b.id) {
					return true;
				}
				if (a === b) {
					return true;
				}
				if (a.id == b) {
					return true;
				}
			}
			return false;
		},
	},
};
</script>
