<template>
  <div>
    <v-simple-table class="">
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left body-2 font-weight-medium">{{ $t("Concepto") }}</th>
            <th class="text-left body-2 font-weight-medium">{{ $t("Método de Pago") }}</th>
            <th class="text-left body-2 font-weight-medium">{{ $t("No. Referencia") }}</th>
            <th class="text-left body-2 font-weight-medium">{{ $t("Valor Recibido") }}</th>
            <th style="width: 90px"></th> 
          </tr>
        </thead>
        <tbody>
          <template v-for="(item, index) in items">
            <tr :key="index">
              <td v-if="item._editing && !item._deleted" :colspan="5">
                <DetailRowEdit @delete="deleteItem(item)" @cancel="cancelEdit(item)" @save:item="saveRowItem"
                  :item="getCopyItem(item)"></DetailRowEdit>
              </td>
              <template v-else-if="!item._deleted">
                <td>
                  <span v-if="item.rule">{{ item.rule.name }}</span>
                </td>
                <td>
                  <span v-if="item.payment_method">{{ item.payment_method.name }}</span>
                </td>
                <td>
                  <span v-if="item.reference_number">{{ item.reference_number }}</span>
                </td>
                <td>
                  <span v-if="item.received_value">{{ $filters.currency(item.received_value, 0) }}</span>
                </td>
                <td>
                  <div v-if="form && !disabled">
                    <v-btn @click="edit(item)" x-small icon :title="$t('edit')" color="primary">
                      <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                    <v-btn @click="deleteItem(item)" x-small icon :title="$t('delete')" color="error">
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </div>
                </td>
              </template>
            </tr>
          </template>
        </tbody>
      </template>
    </v-simple-table>

    <div class="d-flex justify-end mt-4 mb-4" v-if="!disabled">
      <v-btn small color="primary" :title="$t('Agregar Detalle')" @click="addItem" :disabled="!allowAddItem() || itemCurrentlyBeingEdited()"><v-icon>mdi-plus</v-icon>
          {{ $t("Agregar Detalle") }}</v-btn>
      <!--v-btn small color="primary" @click="addItem" :disabled="!allowAddItem()"><v-icon>mdi-plus</v-icon> Adicionar
        item</v-btn-->
    </div>
  </div>
</template>
<script>
import DetailRowEdit from "./DetailRowEdit.vue";
import NextMixinDetails from "@/components/mixins/NextMixinDetails";
import MixinDetailsExpense from "./MixinDetailsExpense";

export default {
  name: "NgDetailsDesktopView",
  mixins: [NextMixinDetails, MixinDetailsExpense],
  components: { DetailRowEdit },
  data() {
    return {};
  },
  props: [

  ],
  methods: {
    deleteItem(item) {
      this.currentItem = null;
      for (const i in this.items) {
        if (this.items[i].id == item.id) {
          this.items[i]._editing = false;
          this.items[i]._deleted = true;
          if (item.id.indexOf("_") >= 0) {
            this.items.splice(i, 1);
          }
          this.$emit("input", this.items);
        }
      }
    }
  },
};
</script>
