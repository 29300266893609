<template>
  <v-row no-gutters>
    <v-col cols="12" class="panel-left-expense">
      <v-tabs show-arrows>
        <v-tab class="btn-expense-tab">
          {{ $t("general_info") }}
        </v-tab>
        <v-tab-item>
          <v-sheet outlined>
            <div class="d-flex flex-column justify-center">
              <ValidationObserver v-slot="{ invalid, errors }" ref="form">
                <v-container fluid>
                  <v-row no-gutters>
                    <v-col cols="12">
                      <v-card elevation="0" class="px-2 py-4 no-shadow">
                        <v-row no-gutters>
                          <v-col
                            class="px-1 input-form-expense_date"
                            cols="12"
                            md="6"
                          >
                            <ng-date
                              v-model="form.expense_date"
                              label="expense_date"
                              :rules="rules['expense_date']"
                              :disabled="!allowUpdate()"
                            ></ng-date>
                          </v-col>
                          <v-col
                            class="px-1 input-form-person"
                            cols="12"
                            md="6"
                          >
                            <ng-auto
                              v-model="form.person"
                              label="Persona/Empresa"
                              :rules="rules['person']"
                              :disabled="!allowUpdate()"
                              url="api/v1/list/person"
                            ></ng-auto>
                          </v-col>
                          <v-col class="px-1 input-form-observations" cols="12">
                            <ng-textarea
                              v-model="form.observations"
                              label="observations"
                              :rules="rules['observations']"
                              :disabled="!allowUpdate()"
                            ></ng-textarea>
                          </v-col>
                          <v-col
                            cols="12"
                            :class="$store.getters.mobile ? 'px-1' : 'px-4'"
                            class="title font-weight-regular"
                            >{{ $t("expense.details") }}</v-col
                          >
                          <v-col cols="12" class="px-1">
                            <div v-if="!$store.getters.mobile">
                              <FormDetailsDesktopView
                                :items="form.details"
                                :form="form"
                                :disabled="!allowUpdate()"
                                @add:item="addItemToDetails"
                                @save:item="saveRowItem"
                              ></FormDetailsDesktopView>
                            </div>
                            <div v-else>
                              <FormDetailsMobileView
                                :items="form.details"
                                :form="form"
                                :disabled="!allowUpdate()"
                                @add:item="addItemToDetails"
                                @save:item="saveRowItem"
                              ></FormDetailsMobileView>
                            </div>
                          </v-col>
                        </v-row>
                        <default-errors :errors="errors"></default-errors>
                        <v-row no-gutters>
                          <v-col cols="12">
                            <div class="py-2 mt-2 text-right">
                              <v-btn
                                small
                                :block="$store.getters.mobile"
                                :class="$store.getters.mobile ? 'mb-2' : ''"
                                color="secondary"
                                outlined
                                :title="$t('cancel')"
                                @click="cancel"
                                class="mr-2"
                              >
                                <v-icon small>mdi-close</v-icon>
                                {{ $t("cancel") }}
                              </v-btn>
                              <v-btn
                                id="btn-expense-delete"
                                class="mr-2"
                                small
                                :block="$store.getters.mobile"
                                :class="$store.getters.mobile ? 'mb-2' : ''"
                                v-if="item && can(item._actions, 'delete')"
                                :disabled="invalid"
                                :color="'error'"
                                :title="$t('delete')"
                                @click="executeDelete()"
                              >
                                <v-icon small>mdi-delete</v-icon>
                                {{ $t("delete") }}
                              </v-btn>
                              <v-btn
                                id="btn-expense-annulled"
                                class="mr-2"
                                small
                                :block="$store.getters.mobile"
                                :class="$store.getters.mobile ? 'mb-2' : ''"
                                v-if="item && can(item._actions, 'annulled')"
                                :disabled="invalid || disabledChangeState()"
                                :color="'error'"
                                :title="$t('Anular')"
                                @click="executeAnnulled()"
                              >
                                <v-icon small>mdi-cancel</v-icon>
                                {{ $t("Anular") }}
                              </v-btn>
                              <v-btn
                                id="btn-expense-create"
                                class="mr-2"
                                small
                                :block="$store.getters.mobile"
                                :class="$store.getters.mobile ? 'mb-2' : ''"
                                v-if="!item"
                                :disabled="invalid"
                                :color="'primary'"
                                :title="$t('save_and_edit')"
                                @click="executeCreate()"
                              >
                                <v-icon small>mdi-content-save</v-icon>
                                {{ $t("save_and_edit") }}
                              </v-btn>
                              <v-btn
                                id="btn-expense-update"
                                class="mr-2"
                                small
                                :block="$store.getters.mobile"
                                :class="$store.getters.mobile ? 'mb-2' : ''"
                                v-if="
                                  item &&
                                  can(item._actions, 'update') &&
                                  allowUpdate()
                                "
                                :disabled="invalid"
                                :color="'primary'"
                                :title="$t('update')"
                                @click="executeUpdate()"
                              >
                                <v-icon small>mdi-content-save</v-icon>
                                {{ $t("update") }}
                              </v-btn>
                              <v-btn
                                id="btn-expense-finalized"
                                class="mr-2"
                                small
                                :block="$store.getters.mobile"
                                :class="$store.getters.mobile ? 'mb-2' : ''"
                                v-if="item && can(item._actions, 'finalized')"
                                :disabled="invalid || disabledChangeState()"
                                :color="'primary'"
                                :title="$t('Aprobar')"
                                @click="executeFinalized()"
                              >
                                <v-icon small>mdi-check</v-icon>
                                {{ $t("Aprobar") }}
                              </v-btn>
                            </div>
                          </v-col>
                        </v-row>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-container>
              </ValidationObserver>
            </div>
          </v-sheet>
        </v-tab-item>
      </v-tabs>
    </v-col>
    <v-col cols="12" class="pl-0 pl-md-3 mt-3 mt-md-0 panel-right-expense">
    </v-col>
  </v-row>
</template>
    <script>
import MixinConfirm from "@/components/mixins/MixinConfirm";
import FormDetailsDesktopView from "./FormDetailsDesktopView.vue";
import FormDetailsMobileView from "./FormDetailsMobileView.vue";
import MixinForm from "./MixinForm";

export default {
  mixins: [MixinConfirm, MixinForm],
  components: { FormDetailsDesktopView, FormDetailsMobileView },
  props: ["item", "urlSave", "urlGet"],
  data() {
    return {
      rules: {},
      form: {},
    };
  },

  mounted() {
    this.setRules();
    this.clearForm();
  },
  methods: {
    clearForm() {
      this.form = {
        expense_date: null,
        person: null,
        observations: null,
        details: null,
      };
    },
    setRules() {
      this.rules = {
        expense_date: "required",
        person: "required",
        observations: "",
        details: "required",
      };
    },
    getValues() {
      let values = {};
      if (
        !this.item ||
        this.hasChanged(this.item["expense_date"], this.form["expense_date"])
      ) {
        values["expense_date"] = this.form["expense_date"];
      }
      if (
        !this.item ||
        this.hasChanged(this.item["person"], this.form["person"])
      ) {
        values["person"] = this.form["person"] ? this.form["person"].id : null;
      }
      if (
        !this.item ||
        this.hasChanged(this.item["observations"], this.form["observations"])
      ) {
        values["observations"] = this.form["observations"];
      }
      if (
        !this.item ||
        this.hasChanged(this.item["details"], this.form["details"])
      ) {
        values["details"] = this.form["details"];
      }
      return values;
    },
  },
};
</script>
